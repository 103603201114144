import { CDataTooltip, CDataTooltipType } from "./CDataTooltip";
import "./InfoIcon.scss";

export const InfoIcon = (props: {
  tooltipMessage: string;
  className?: string;
  iconId?: string;
}) => {
  return (
    <CDataTooltip type={CDataTooltipType.Dark} title={props.tooltipMessage}>
      <div className="info-icon-div">
        <span
          id={props.iconId ? `${props.iconId}` : "info-icon"}
          className={`info-icon-container ${props.className || ""}`}
        >
          <i className="fa fa-solid fa-circle-info fa-sm" />
        </span>
      </div>
    </CDataTooltip>
  );
};
